import ReactOnRails from "react-on-rails";
import { AdminNavigation } from "@Ignite-Reading/ui-kit/components";

import "../application";
import AdminApp from "../components/AdminApp";
import GlobalPairingsFilters from "../components/GlobalPairingsFilters";
import GlobalSessionsFilters from "../components/GlobalSessionsFilters";
import StudentHighFrequencyWordsMasteryDrawerTrigger from "../components/StudentHighFrequencyWordsMasteryDrawerTrigger";
import StudentsFilters from "../components/StudentsFilters";
import StudentDearAssessment from "../components/StudentDearAssessment";
import StudentFluencyAssessment from "../components/StudentFluencyAssessment";
import StudentOneMinuteChallenge from "../components/StudentOneMinuteChallenge";
import StudentPhonologicalAwarenessDrill from "../components/StudentPhonologicalAwarenessDrill";
import StudentAssessmentsResults from "../components/StudentAssessmentsResults";
import TutorApp from "../components/TutorApp";
import AutoReteachFocusBanner from "../components/AutoReteachFocusBanner";
import CopyToClipboardButtonForERB from "../components/common/CopyToClipboardButtonForERB";
import OneLessonADayBadge from "../components/common/Badges/OneLessonADay";

ReactOnRails.setOptions({ turbo: true });
ReactOnRails.register({
  AdminApp,
  AdminNavigation,
  AutoReteachFocusBanner,
  CopyToClipboardButtonForERB,
  GlobalPairingsFilters,
  GlobalSessionsFilters,
  OneLessonADayBadge,
  StudentAssessmentsResults,
  StudentDearAssessment,
  StudentFluencyAssessment,
  StudentHighFrequencyWordsMasteryDrawerTrigger,
  StudentOneMinuteChallenge,
  StudentPhonologicalAwarenessDrill,
  StudentsFilters,
  // we render students' view within admin pages, that's why TutorApp is included here as well
  TutorApp,
});
